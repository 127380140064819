import dayjs from "dayjs";
import React, { createContext, useEffect, useState } from "react";

interface SearchParticulars {
  checkin: any;
  checkout: any;
  guestsAdult: number;
  guestsChildren: object[];
}

interface Context {
  searchParticulars: SearchParticulars;
  setSearchParticulars: (searchParticulars: any) => void;
}

const initialValues: Context = {
  searchParticulars: {
    checkin: null,
    checkout: null,
    guestsAdult: 1,
    guestsChildren: [] as object[],
  },
  setSearchParticulars: (searchParticulars: any) => {},
};

export const SearchContext = createContext(initialValues);

export const SearchContextProvider = ({ children }: any) => {
  const [searchParticulars, setSearchParticulars] = useState(
    initialValues.searchParticulars
  );

  //  On load, set the default search to be three months in the future.
  useEffect(() => {
    setSearchParticulars({
      checkin: dayjs().add(3, "month"),
      checkout: dayjs().add(3, "month").add(1, "day"),
      guestsAdult: 2,
      guestsChildren: [] as object[],
    });
  }, []);

  const contextValue = {
    searchParticulars,
    setSearchParticulars,
  };

  return (
    <SearchContext.Provider value={contextValue}>
      {children}
    </SearchContext.Provider>
  );
};
