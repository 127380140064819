const React = require("react");

import "./src/styles/global.css";
import "@fontsource/roboto";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import { SignupProvider } from "./src/contexts/signup.context";
import { SearchContextProvider } from "./src/contexts/search-context";

export const wrapRootElement = ({ element }: any) => (
  <SearchContextProvider>
    <SignupProvider>{element}</SignupProvider>
  </SearchContextProvider>
);
