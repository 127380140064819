import React, { createContext, useEffect, useState } from "react";

// Initial state values for userInfo and smsConfirmationData
const initialValues = {
  userInfo: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    residency: "",
    password: "",
    confirmPassword: "",
  },
  smsConfirmationData: {
    sessionId: "",
  },
  setUserInfo: (userInfo: any) => {},
  setSmsConfirmationData: (smsConfirmationData: any) => {},
};

// Create the context with initial values
export const SignupContext = createContext(initialValues);

// Context provider component
export const SignupProvider = ({ children }: any) => {
  const [userInfo, setUserInfo] = useState(initialValues.userInfo);
  const [smsConfirmationData, setSmsConfirmationData] = useState(
    initialValues.smsConfirmationData
  );

  useEffect(() => {
    console.log("UPDATED USER INFO")
    console.log(userInfo);
  })

  // Passing state and updater functions to context value
  const contextValue = {
    userInfo,
    smsConfirmationData,
    setUserInfo,
    setSmsConfirmationData,
  };

  return (
    <SignupContext.Provider value={contextValue}>
      {children}
    </SignupContext.Provider>
  );
};
